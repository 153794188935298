@charset 'UTF-8';
@import './setting/variables';

.C0043 {
	max-width:(1284px+60px);
	padding-left:30px;
	padding-right:30px;
	@include screen(custom, max, $max-sm) {
		padding-left:24px;
		padding-right:24px;
	}
	@extend %clearfix;
	width:100%;
	@mixin square($calc-type, $part-size, $container-width){
		@if($calc-type == "w"){
			width:($part-size * 100%) / $container-width;
		};
		@if($calc-type == "m"){
			margin-left:($part-size * 100%) / $container-width;
		};
	}
	@include screen(custom, min, $min-lg) {
		&.standard {
			.visual-wrap {
				@include square(w, 640px, 1284px);
			}
			.text-wrap {
				@include square(w, 604px, 1284px);
			}
		}
		&.large {
			.visual-wrap {
				@include square(w, 763px, 1284px);
			}
			.text-wrap {
				@include square(w, 481px, 1284px);
			}
		}
	}
	.visual-wrap {
		width:100%;
		// float:none;
		@include screen(custom, min, $min-lg) {
			&.image-align-left {
				float:left;
			}
			&.image-align-right {
				float:right;
				@include square(m, 40px, 1284px);
			}
		}
		.visual-area {
			position:relative;
			max-width:100%;
			margin-bottom:14px;	
		}
		img {
			max-width:100%;
			width:100%;
		}
		.desc {
			font-size:14px;
			line-height:20px;
			color:$color-gray;
		}
		.controller-wrap {
			margin-bottom: 50px;
			@include screen(custom, max, $max-sm) {
				margin-bottom: 40px;
			}
		}
	}
	
	.text-wrap {
		width:100%;
		// float:none;
		padding-top:25px;
		@include screen('md') {
			padding-top:20px;
		}
		@include screen(custom, max, $max-sm) {
			padding-top:14px;
		}
		@include screen(custom, min, $min-xl) {
			&.side-right-text {
				padding-right:40px;
			}
			&.side-left-text {
				padding-left:40px;
			}
		}	
		@include screen(custom, min, $min-lg) {
			&.side-right-text {
				float:right;
				vertical-align: middle;
			}
			&.side-left-text {
				float:left;
				vertical-align: middle;
			}
		}	
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			font-size:36px;
			line-height: 44px;
			color:$color-black;
			@include font-family($font-semibold);
			margin-bottom:16px;
			@include screen(custom, max, $max-lg) {
				font-size:32px;
				line-height:40px;
			}
			@include screen(custom, max, $max-sm) {
				font-size:24px;
				line-height:28px;
				margin-bottom:8px;
			}
		}
		.small-title {
			display:block;
			font-size:18px;
			line-height:24px;
			color:$color-nightrider;
			@include font-family($font-semibold);
			margin-bottom:7px;
			@include screen(custom, max, $max-sm) {
				font-size:16px;	
				line-height:24px;
				margin-bottom:3px;
			}
		}
		.text {
			font-size:16px;
			line-height:24px;
			color:$color-dimgray;
			margin-bottom:24px;
			@include screen(custom, max, $max-sm) {
				margin-bottom:19px;
			}
		}
		.link-text {
			color:$color-nightrider;
			&:after {
				background:url(/lg5-common/images/common/icons/link-right-nightrider.svg) no-repeat 0 1px;
			}
		}
	}
	// 버튼 사이 간격
	&.align-left {
		.btn-type-box {
			.btn {
				margin:0 6px 10px 0;
			}
		}
		.btn-type-text {
			.link-text {
				margin:0 18px 11px 0;
			}
		}
	}
	&.align-right {
		.btn-type-box {
			.btn {
				margin:0 0 10px 6px;
			}
		}
		.btn-type-text {
			.link-text {
				margin:0 0 11px 18px;
			}
		}
	}
	&.align-center {
		.btn-type-box {
			.btn {
				margin:0 3px 10px 3px;
			}
		}
		.btn-type-text {
			.link-text {
				margin:0 9px 11px 9px;
			}
		}
	}
}